import React, { useEffect, useRef, useState } from 'react'
import DashboardStyle from '../Dashboard'
import { customFuncsApi } from '../../../api'
import { get } from 'lodash'
import { ErrorModal } from '../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
const Tab2 = () => {
	const errorRef = useRef()

	const [startDate, setStartDate] = useState(
		moment().startOf('month').format('YYYY-MM-DD'),
	)
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

	const [salesPersonLoading, setSalesPersonLoading] = useState(false)

	const [selesPersonSalesData, setSalesPersonSalesData] = useState([])

	const getSalesPersonSales = () => {
		setSalesPersonLoading(true)
		customFuncsApi(
			`xsSql/getSalesPersonSales?startDate=${startDate}&endDate=${endDate}`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			},
		)
			.then((res) => {
				const resData = res.data.value
				setSalesPersonSalesData(resData)
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setSalesPersonLoading(false)
			})
	}

	useEffect(() => {
		getSalesPersonSales()
	}, [startDate, endDate])

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value)
	}

	const handleEndDateChange = (event) => {
		setEndDate(event.target.value)
	}

	return (
		<DashboardStyle>
			<div className="container">
				<p className="productTitle mb-10">Показатели продаж</p>
				<div className="flex gap-4">
					<div>
						<p className={'font-medium text-zinc-600 text-sm mb-1'}>
							Дата начала
						</p>
						<input
							className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md'}
							type="date"
							value={startDate}
							onChange={handleStartDateChange}
						/>
					</div>
					<div>
						<p className={'font-medium text-zinc-600 text-sm mb-1'}>
							Дата окончания
						</p>
						<input
							className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md '}
							type="date"
							value={endDate}
							onChange={handleEndDateChange}
						/>
					</div>
				</div>
				<div>
					<h4 className={'font-bold text-lg text-center mt-10 mb-4'}>
						Показатели продаж
					</h4>
					{salesPersonLoading ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<ClipLoader
								loading={true}
								size={20}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						</div>
					) : (
						<div className="overflow-y-auto mb-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											Имя продавца
										</th>
										<th scope="col" className="px-6 py-4">
											Наличные продажи
										</th>
										<th scope="col" className="px-6 py-4">
											Рассрочка
										</th>
									</tr>
								</thead>
								<tbody>
									{selesPersonSalesData?.map((v, i) => {
										return (
											<tr
												key={i}
												className="bg-white border-b  hover:bg-gray-50 "
											>
												<td
													scope="row"
													className="px-6 py-4 font-medium text-gray-900 "
												>
													{get(v, 'Sales Employee', '')}
												</td>
												<td className="px-6 py-4">
													{get(v, 'Single Installment', '')}
												</td>
												<td className="px-6 py-4">
													{' '}
													{get(v, 'Multiple Installments', '')}
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</DashboardStyle>
	)
}

export default Tab2
