import React, { memo, useEffect, useState, useRef } from 'react'
import CreateUserStyle from './CreateUserStyle'
import Modal from 'react-modal'
import moment from 'moment'
import { get } from 'lodash'
import _ from 'lodash'
import api, { customFuncsApi } from '../../../api'
import { ErrorModal, SuccessModal } from '..'
import { Button as AntButton } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isAxiosError } from 'axios'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
}
const handleBusinessPartner = _.debounce((searchFunc, value) => {
	searchFunc(value)
}, 1000)
const AddTask = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	executer = [],
}) => {
	const { getMe } = useSelector((state) => state.main)
	const navigate = useNavigate()

	const errorRef = useRef()
	const succesRef = useRef()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [loadingBtn, setLoadingBtn] = useState(false)
	const [employeeID, setEmployeeID] = useState(
		get(getMe, 'Department2.Name', '') === 'Undiruv'
			? get(getMe, 'EmployeeID', '')
			: '',
	)
	const sta = 'Bajarilmagan'
	const [employeeChange, setEmployeeChange] = useState(true)
	const [imei, setImei] = useState(null)
	const [imeiData, setImeiData] = useState([])

	const [task, setTask] = useState('')
	const [deadLine, setDeadLine] = useState('')
	const [statusTask, setStatusTask] = useState(sta)
	const [docEntry, setDocEntry] = useState('')

	const resetForm = () => {
		setEmployeeID('')
		setImei('')
		setTask('')
		setDeadLine('')
		setStatusTask(sta)
		setDocEntry('')
	}

	useEffect(() => {
		const ref = {
			open: (v) => {
				resetForm()

				setIsOpenModal(true)
			},
			close: () => {
				setIsOpenModal(false)
			},
		}
		getRef(ref)
	}, [])

	const addTask = () => {
		setLoadingBtn(true)
		console.log(statusTask)
		api
			.patch(`Invoices(${docEntry})`, {
				// U_Dunner: employeeID,
				U_DeadLine: moment(deadLine).format('YYYY-MM-DD'),
				U_Task: task,
				U_StatusDunner: statusTask === 'Bajarilmagan' ? 'NotYet' : 'Done',
			})
			.then(() => {
				succesRef.current?.open('Задача успешно Создана')
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setLoadingBtn(false)
			})
	}

	const searchImei = async (value) => {
		const url = `xsSql/getTasks`
		const params = {
			hasTask: 'N',
			isProved: 'Yes',
			imei: value,
			dunnerStatus: 'NotYet',
			dunner: get(getMe, 'Name', ''),
		}
		customFuncsApi
			.get(url, {
				params,
			})
			.then((res) => {
				const resData = res.data.value
				setImeiData(resData)

				setLoadingBtn(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<h2 className="font-bold text-xl text-center mb-5">
						{'Добавить задачу'}
					</h2>

					<div
						// onClick={() => viewItem(v)}
						className=" flex flex-col items-start gap-2 w-full"
					>
						<div className="flex items-center justify-center gap-5 w-full">
							{' '}
							<div className="flex flex-col items-start gap-2 w-full">
								<p className={'font-bold'}>Приёмщик</p>
								<select
									name="executer"
									id="executer"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md  w-full'
									}
									onChange={(v) => {
										setEmployeeID(v.target.value)
									}}
									disabled={employeeChange}
									defaultValue={'s'}
								>
									<option value={''}></option>

									{executer.map((v, i) => {
										return (
											<option
												value={get(v, 'EmployeesInfo.EmployeeID', 0)}
												key={i}
												selected={v.EmployeesInfo.EmployeeID === employeeID}
											>
												{get(v, 'EmployeesInfo.FirstName', 'Name')}{' '}
												{get(v, 'EmployeesInfo.LastName', 'Surename')}
											</option>
										)
									})}
								</select>
							</div>
							<div className="flex flex-col items-start gap-2  w-full">
								<p className="font-bold">Дедлайн</p>
								<input
									type="date"
									className="border-[1px] border-[#DFE2E9] p-2 rounded-md  w-full"
									onChange={(e) => setDeadLine(e.target.value)}
								/>
							</div>
						</div>

						<div className="flex items-start justify-center gap-5  w-full">
							<div className="relative  w-full">
								<p className="font-bold mb-1 text-left">Имеи(*)</p>
								<input
									className="border-[1px] border-[#d9d9d9] p-2 rounded-md  w-full text-left"
									type="text"
									placeholder="Имеи"
									value={imei}
									onChange={(e) => {
										setImei(e.target.value)
										handleBusinessPartner(searchImei, e.target.value)
									}}
								/>
								{imei && (
									<div className="absolute top-18 left-0 bg-white w-full z-10 border-[1px] border-[#d9d9d9] max-h-[300px] overflow-y-scroll">
										{imeiData.map((v, i) => {
											return (
												<div
													key={i}
													className="p-2 border-b cursor-pointer hover:bg-gray-100"
													onClick={() => {
														setImei(v.IntrSerial)
														setDocEntry(v.DocEntry)
														setEmployeeID(v.U_Dunner)
														setEmployeeChange(
															v.U_Dunner === null ? false : true,
														)
														setImeiData([])
													}}
												>
													{v.IntrSerial}
												</div>
											)
										})}
									</div>
								)}
								{!imei && (
									<p className="text-red-500 text-sm">Имеи обязательно</p>
								)}
							</div>
							{get(getMe, 'Department2.Name', '') !== 'Undiruv' ? (
								<div className="flex flex-col items-start gap-2  w-full">
									<p className="font-bold">Cтатус</p>
									<select
										name="n"
										className='border-[1px] border-["#DFE2E9"] p-2 rounded-md  w-full'
										id=""
										onChange={(e) => setStatusTask(e.target.value)}
										value={statusTask}
									>
										<option value="Bajarilmagan">Bajarilmagan</option>
										<option value="Bajarilgan">Bajarilgan</option>
									</select>
								</div>
							) : (
								<select
									name="n"
									className='border-[1px] border-["#DFE2E9"] p-2 rounded-md w-full'
									id=""
									onChange={(e) => setStatusTask(e.target.value)}
									defaultValue={statusTask}
									disabled
								></select>
							)}
						</div>

						<div className="flex flex-col items-start gap-2 w-full">
							<p className="font-bold">Задача</p>
							<textarea
								type="text"
								className="border-[1px] border-[#DFE2E9] p-2 rounded-md w-full"
								onChange={(e) => setTask(e.target.value)}
							/>
						</div>
					</div>

					<div className="flex items-center justify-end">
						<AntButton
							className="bg-[#0A4D68] text-white p-2 mt-5 h-10 w-full sm:w-1/2"
							onClick={addTask}
							loading={loadingBtn}
							disabled={!imei || !task || !deadLine}
						>
							{'Добавить'}
						</AntButton>
					</div>
				</div>
			</CreateUserStyle>

			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
				onConfirm={() => {
					setIsOpenModal(false)
				}}
			/>
			<SuccessModal
				getRef={(r) => {
					succesRef.current = r
				}}
				onConfirm={() => {
					setIsOpenModal(false)
					onClose()
					resetForm()
				}}
			/>
		</Modal>
	)
}

export default memo(AddTask)
