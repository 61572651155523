import React, { useEffect, useRef, useState } from 'react'
import AllProductsStyle from './AllProducts'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { roundCurrency } from '../../utils'

const AllProductsPartner = () => {
	const navigation = useNavigate()
	const errorRef = useRef()
	const { getMe } = useSelector((state) => state.main)
	const [isLoading, setIsLoading] = useState(false)
	const [clientName, setClientName] = useState('')
	const [clientPhone, setClientPhone] = useState('')
	const [clientCode, setClientCode] = useState('')
	const [product, setProduct] = useState('')
	const [data, setData] = useState({
		data: [],
		currentPage: 0, //20
	})
	const [currentPage, setCurrentPage] = useState(0)

	const search = () => {
		setIsLoading(true)
		customFuncsApi
			.get(
				`XsSql/getPartnerPurchaseAndInvoices`,
				{
					params: {
						$skip: currentPage,
						SlpCode: getMe.SalesPersonCode,
						WhsCode: `${getMe.U_Warehouse}`,
					},
				},
				{
					headers: {
						Prefer: 'odata.maxpagesize=10',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value

				setData({
					data: [...resData],
				})

				setIsLoading(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search()
	}, [currentPage])

	const viewItem = (v) => {
		navigation('/viewPartnerInvoice', { state: v })
	}

	const newDatas = () => {
		if (data.data.length >= 10) {
			setCurrentPage((prevState) => prevState + 10)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldData = () => {
		if (currentPage <= 0) {
			alert('boshqa malumot yoq')
		} else {
			setCurrentPage((prevState) => prevState - 10)
		}
	}

	return (
		<Layout>
			<AllProductsStyle>
				<div className="container">
					<p className="productTitle">Продажи</p>
					{/*<div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">*/}
					{/*  <input*/}
					{/*    type="text"*/}
					{/*    className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'}*/}
					{/*    placeholder="Имя Клиента"*/}
					{/*    defaultValue={clientName}*/}
					{/*    onChange={(v) => setClientName(v.target.value)}*/}
					{/*  />*/}
					{/*  <input*/}
					{/*    type="text"*/}
					{/*    className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'}*/}
					{/*    placeholder="Телефон клиента"*/}
					{/*    defaultValue={clientPhone}*/}
					{/*    onChange={(v) => setClientPhone(v.target.value)}*/}
					{/*  />*/}
					{/*  <input*/}
					{/*    type="text"*/}
					{/*    className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'}*/}
					{/*    placeholder="Код клиента"*/}
					{/*    defaultValue={clientCode}*/}
					{/*    onChange={(v) => setClientCode(v.target.value)}*/}
					{/*  />*/}
					{/*  <input*/}
					{/*    type="text"*/}
					{/*    className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'}*/}
					{/*    placeholder="Товар"*/}
					{/*    defaultValue={product}*/}
					{/*    onChange={(v) => setProduct(v.target.value)}*/}
					{/*  />*/}
					{/*  <Button onClick={() => search(0, 0)} isLoading={isLoading}>*/}
					{/*    Поиск*/}
					{/*  </Button>*/}
					{/*</div>*/}

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto my-8">
								<table className="w-full text-sm text-left rtl:text-right text-black ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												Код клиента
											</th>
											<th scope="col" className="px-6 py-4">
												Имя Клиента
											</th>
											<th scope="col" className="px-6 py-4">
												Товар
											</th>
											<th scope="col" className="px-6 py-4">
												Деньги
											</th>
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
											<th scope="col" className="px-6 py-4">
												Дата продажи
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.map((v, i) => {
											return (
												<tr
													className={'bg-white border-b  hover:bg-gray-50'}
													key={i}
													onClick={() => viewItem(v)}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{get(v, 'CardCode', 'Код покупателя')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'CardName', 'Имя покупателя')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'Dscription', '')}
													</td>
													<td className="px-6 py-4">
														{roundCurrency(get(v, 'LineTotal', ''))}
													</td>
													<td className="px-6 py-4">
														{roundCurrency(get(v, 'PaidToDate', ''))}
													</td>
													<td className="px-6 py-4">
														{moment(get(v, 'DocDate', '')).format('DD-MM-YYYY')}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<div className="flex">
									<Button
										disabled={currentPage === 0}
										className={'btn'}
										onClick={oldData}
										btnStyle={{ marginRight: 10 }}
									>
										{'<'}
									</Button>
									<Button
										disabled={data.data.length < 10}
										className={'btn'}
										onClick={newDatas}
									>
										{'>'}
									</Button>
								</div>
								<Button
									className={'btn'}
									onClick={() => navigation('/create-products-partner')}
								>
									{'+'}
								</Button>
							</div>
						</>
					)}
				</div>
			</AllProductsStyle>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default AllProductsPartner
