import React, { useState } from 'react'
import Layout from '../../components/Layout'
import { Space } from 'antd'
import Tab1 from './Tab-1'
import Tab2 from './Tab-2'

const Dashboard = () => {
	const [activeTab, setActiveTab] = useState('tab1')

	const renderContent = () => {
		switch (activeTab) {
			case 'tab1':
				return <Tab1 />
			case 'tab2':
				return <Tab2 />

			default:
				return null
		}
	}

	return (
		<Layout>
			<div>
				<h1 className="text-2xl font-bold text-center">Главная страница</h1>
				<Space style={{ marginBottom: 16 }} className="mt-10 m-5">
					<button
						className={`${
							activeTab === 'tab1'
								? 'text-blue-900 underline underline-offset-8'
								: ''
						} font-semibold `}
						onClick={() => setActiveTab('tab1')}
					>
						Основные показатели
					</button>
					<button
						className={`${
							activeTab === 'tab2'
								? 'text-blue-900 underline underline-offset-8'
								: ''
						} font-semibold `}
						onClick={() => setActiveTab('tab2')}
					>
						Показатели продаж
					</button>
				</Space>
				<div className="mt-3">{renderContent()}</div>
			</div>
		</Layout>
	)
}

export default Dashboard
