import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import {
	Login,
	Launch,
	Clients,
	Recovery,
	ViewItem,
	Download,
	AllProducts,
	UZ_Download,
	DollarCourse,
	RecoveryChart,
	CreateProducts,
	IStyleDownload,
	ViewRecoveryItem,
	SettingsPage,
	OutgoingPaymentPage,
	CashFlowReportPage,
	DDSReportPage,
	Dashboard,
	PurchasesPage,
	ConstantConsumptionPage,
	PaySupplierPage,
	AllProductsPartner,
	CreateProductsPartner,
	CashPaymentPage,
	InstallmentPlanPage,
	CreateProductsInstallmentPage,
	CreateProductsCashPaymentPage,
	SMSPage,
	Task,
} from '../screens'
import CreatePurchasesPage from '../screens/CreatePurchases'
import Convert from '../screens/Convert'
import Logs from '../screens/Logs'
import PurchaseInvoiceView from '../screens/PurchaseInvoiceView'

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/recovery" element={<Recovery />} />
				<Route path="/clients" element={<Clients />} />
				<Route path="/download" element={<Download />} />
				<Route path="/viewItem" element={<ViewItem />} />
				<Route path="/uz_download" element={<UZ_Download />} />
				<Route path="/allProducts" element={<AllProducts />} />
				<Route path="/dollarCourse" element={<DollarCourse />} />
				<Route path="/ru_download" element={<IStyleDownload />} />
				<Route path="/recoveryChart" element={<RecoveryChart />} />
				<Route path="/createProducts" element={<CreateProducts />} />
				<Route path="/viewRecoveryItem" element={<ViewRecoveryItem />} />
				<Route path="/outgoing-payment" element={<OutgoingPaymentPage />} />
				<Route path="/cash-flow-report" element={<CashFlowReportPage />} />
				<Route path="/dds-report" element={<DDSReportPage />} />
				<Route path="/task" element={<Task />} />
				<Route path="/settings" element={<SettingsPage />} />
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/purchases" element={<PurchasesPage />} />
				<Route path="/create-purchases" element={<CreatePurchasesPage />} />
				<Route
					path="/constant-consumption"
					element={<ConstantConsumptionPage />}
				/>
				<Route path="/all-products-partner" element={<AllProductsPartner />} />
				<Route
					path="/create-products-partner"
					element={<CreateProductsPartner />}
				/>
				<Route path="/pay-supplier" element={<PaySupplierPage />} />
				<Route path="/cash-payment" element={<CashPaymentPage />} />
				<Route path="/installment-plan" element={<InstallmentPlanPage />} />
				<Route
					path="/createProducts-installment"
					element={<CreateProductsInstallmentPage />}
				/>
				<Route path="/convert" element={<Convert />} />
				<Route
					path="/createProducts-payment"
					element={<CreateProductsCashPaymentPage />}
				/>
				<Route path="/viewPartnerInvoice" element={<PurchaseInvoiceView />} />
				<Route path="/sms" element={<SMSPage />} />
				<Route path="/logs" element={<Logs />} />
				<Route path="/" element={<Navigate to={'/login'} />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
