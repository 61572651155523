import axios from 'axios'
import {
	API_ROOT_PROD,
	API_ROOT_TEST,
	CUSTOM_API_ROOT_PROD,
	CUSTOM_API_ROOT_TEST,
	LOGS_BASE_URL,
	LOGS_BASE_URL_PROD,
	LOGS_BASE_URL_TEST,
} from '../config'

const customMainApi = axios.create({
	baseURL:
		window.location.hostname.includes('icomfort-test') ||
		window.location.hostname.includes('localhost')
			? // API_ROOT_TEST
				API_ROOT_PROD
			: API_ROOT_PROD,
	timeout: 60000,
	headers: {
		Accept: 'application/json',
		'Cache-Control': 'no-cache',
		'Content-Type': 'application/json',
		'B1S-CaseInsensitive': true,
	},
	transformResponse: (data) => {
		return data
	},
	withCredentials: true,
})

customMainApi.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			// Redirect to the login page
			window.location.pathname = '/login'
		}
		return Promise.reject(error)
	},
)

export default customMainApi

const customFuncsApi = axios.create({
	baseURL:
		window.location.hostname.includes('icomfort-test') ||
		window.location.hostname.includes('localhost')
			? //CUSTOM_API_ROOT_TEST
				CUSTOM_API_ROOT_PROD
			: CUSTOM_API_ROOT_PROD,
	timeout: 60000,
	headers: {
		Accept: 'application/json',
		'Cache-Control': 'no-cache',
		'Content-Type': 'application/json',
		'B1S-CaseInsensitive': true,
	},
	withCredentials: true,
})

customFuncsApi.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			window.location.pathname = '/login'
		}
		return Promise.reject(error)
	},
)

const logsUrl = axios.create({
	baseURL:
		window.location.hostname.includes('icomfort-test') ||
		window.location.hostname.includes('localhost')
			? // LOGS_BASE_URL_TEST
				LOGS_BASE_URL_PROD
			: LOGS_BASE_URL_PROD,
})

export { customFuncsApi, logsUrl }
