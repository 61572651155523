import React, { useEffect, useRef, useState } from 'react'
import Purchases from './Purchases'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi, logsUrl } from '../../api'
import _, { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal, PaymentModal, SuccessModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { COMPANY_NAME } from '../../config'

const PurchasesPage = () => {
	const navigation = useNavigate()
	const errorRef = useRef()
	const paymentRef = useRef()
	const successModalRef = useRef()
	const { getMe } = useSelector((state) => state.main)

	const [dollar, setDollar] = useState(1)
	const [isLoading, setIsLoading] = useState(false)
	const [startDate, setStartDate] = useState(
		moment().subtract(1, 'months').format('YYYY-MM-DD'),
	)

	const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0])
	const [imei, setImei] = useState('')
	const [data, setData] = useState({
		data: [],
	})
	const [pagination, setPagination] = useState({
		page: 0,
	})
	const [DocEntry, setDocEntry] = useState(null)
	const [CardCode, setCardCode] = useState(null)

	const [dollarLoading, setDollarLoading] = useState(false)

	const getUSD = () => {
		setDollarLoading(true)
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: 'UZS',
				Date: moment(),
			})
			.then((res) => {
				setDollar(JSON.parse(res.data))
			})
			.catch((err) => {
				setDollar('error')
			})
			.finally(() => setDollarLoading(false))
	}

	useEffect(() => {
		getUSD()
	}, [])

	const search = () => {
		setIsLoading(true)
		customFuncsApi
			.get(
				`xsSql/getLastPurchases?${startDate ? `date1='${startDate + 'T00%3A00%3A00.000Z'}` : "date1='2012-01-01T00%3A00%3A00.000Z'"}&date2='${endDate + 'T00%3A00%3A00.000Z'}'&$skip=${pagination.page * 20}${imei.length ? `&IntrSerial=${imei}%` : ''}${getMe?.Department2.Name === 'Sotuv' || getMe?.Department2.Name === 'Sotuv_sherik' ? `&WhsCode=${getMe?.U_Warehouse}` : ''}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=20',
					},
				},
			)
			.then((res) => {
				const resData = res.data.value

				setData({
					data: [...resData],
				})
				setIsLoading(false)
			})
			.catch((err) => {
				if (isAxiosError(err))
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search(0, 0)
	}, [startDate, startDate, endDate, pagination, imei])

	const handleImei = _.debounce((e) => {
		setImei(e.target.value)
		setStartDate('')
	}, 1000)

	useEffect(() => {
		console.log(startDate)
	}, [startDate])

	const newDatas = () => {
		if (data.data.length < 20) {
			alert('Никакой другой информации')
		} else {
			setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
		}
	}

	const oldData = () => {
		if (pagination.page > 0) {
			setPagination((prev) => ({ ...prev, page: prev.page - 1 }))
		} else {
			alert('Никакой другой информации')
		}
	}

	const handleStartDateChange = (event) => {
		setStartDate(event.target.value)
	}

	const handleEndDateChange = (event) => {
		setEndDate(event.target.value)
	}

	const createPayment = ({
		DocDate,
		CurrencyRate,
		AmountUsd,
		AmountUzs,
		AmountPayme,
		NumberCard,
		Terminal,
	}) => {
		setIsLoading(true)
		const payments = []
		if (AmountUsd) {
			payments.push({
				usdSum: AmountUsd,
				account: getMe.U_CashAccount,
				currency: 'USD',
				uzsSum: null,
			})
		}
		if (AmountUzs) {
			payments.push({
				usdSum: AmountUzs / CurrencyRate,
				account: getMe.U_CashUzsAccount,
				currency: 'UZS',
				uzsSum: AmountUzs,
			})
		}
		if (AmountPayme) {
			payments.push({
				usdSum: AmountPayme / CurrencyRate,
				account: NumberCard || getMe.U_CardAccount,
				currency: 'UZS',
				uzsSum: AmountPayme,
			})
		}
		if (Terminal) {
			payments.push({
				usdSum: Terminal / CurrencyRate,
				account: getMe.U_TerminalAccount,
				currency: 'UZS',
				uzsSum: Terminal,
			})
		}
		customFuncsApi
			.post('addVendorPayments', {
				DocDate,
				DocEntry: DocEntry,
				CardCode: CardCode,
				DocRate: CurrencyRate,
				payments: payments,
			})
			.then((err) => {
				try {
					logsUrl.post('apis', {
						company_name: COMPANY_NAME,
						method: 'post',
						path: `/addVendorPayments`,
						description: 'pokupkida oplatit',
						username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
						body: JSON.stringify({
							DocDate,
							DocEntry: DocEntry,
							CardCode: CardCode,
							DocRate: CurrencyRate,
							payments: payments,
						}),
					})
				} catch (err) {
					errorRef.current?.open(
						'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
					)
				}
				paymentRef.current?.close()
				successModalRef.current?.open('Платеж успешно произведен!')
				search()
			})
			.catch((err) => {
				paymentRef.current?.close()
				errorRef.current?.open(JSON.stringify(err.response.data))
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	return (
		<Layout>
			<Purchases>
				<div className="container">
					<p className="productTitle">Покупки</p>
					<div className={'flex justify-between pr-5'}>
						<div className="searchCard">
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Дата начала
								</p>
								<input
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
									}
									type="date"
									value={startDate}
									onChange={handleStartDateChange}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Дата окончания
								</p>
								<input
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
									}
									type="date"
									value={endDate}
									onChange={handleEndDateChange}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>IMEI</p>
								<input
									className={
										'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
									}
									onChange={handleImei}
								/>
							</div>
						</div>
						<Button
							className={'btn'}
							onClick={() => navigation('/create-purchases')}
						>
							{'+ Добавить'}
						</Button>
					</div>
					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto my-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												Поставщик
											</th>
											<th scope="col" className="px-6 py-4">
												Модель
											</th>
											<th scope="col" className="px-6 py-4">
												С имеи
											</th>
											<th scope="col" className="px-6 py-4">
												IMEI
											</th>
											<th scope="col" className="px-6 py-4">
												Кол-во
											</th>
											<th scope="col" className="px-6 py-4">
												Сумма
											</th>

											<th scope="col" className="px-6 py-4">
												Менеджер продаж
											</th>
											<th scope="col" className="px-6 py-4">
												Дата закупки
											</th>
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
											<th scope="col" className="px-6 py-4"></th>
										</tr>
									</thead>
									<tbody>
										{data.data.map((v, i) => {
											return (
												<tr
													key={i}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{v.CardName || '-'}
													</td>
													<td className="px-6 py-4">{v.Dscription || '-'}</td>
													<td className="px-6 py-4">{v.U_IMEI_PURCH || '-'}</td>
													<td className="px-6 py-4">{v.IntrSerial || '-'}</td>
													<td className="px-6 py-4">{+v.Quantity || '-'}</td>
													<td className="px-6 py-4">
														{(v.DocTotal && Number(v.DocTotal).toFixed(2)) || 0}{' '}
														USD
													</td>
													<td className="px-6 py-4">{v.SlpName || '-'}</td>

													<td className="px-6 py-4">
														{moment(v.DocDate).format('DD-MM-YYYY')}
													</td>
													<td className="px-6 py-4">{v.PaidToDate}</td>
													<td className="px-6 py-4">
														<Button
															disabled={dollarLoading}
															onClick={() => {
																setDocEntry(v.DocEntry)
																setCardCode(v.CardCode)
																paymentRef.current?.open()
															}}
														>
															Оплатить
														</Button>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>

							<div className="topCard">
								<div className="flex">
									<Button
										className={'btn'}
										onClick={oldData}
										btnStyle={{ marginRight: 10 }}
									>
										{'<'}
									</Button>
									<Button className={'btn'} onClick={newDatas}>
										{'>'}
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</Purchases>
			<>
				{dollarLoading ? null : (
					<PaymentModal
						getRef={(r) => (paymentRef.current = r)}
						onConfirm={(form) => createPayment(form)}
						onClose={() => paymentRef.current?.close()}
						loading={isLoading}
						currencyRate={dollar}
						refundShow={false}
					/>
				)}
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
				<SuccessModal
					getRef={(r) => (successModalRef.current = r)}
					onConfirm={() => successModalRef.current?.close()}
				/>
			</>
		</Layout>
	)
}

export default PurchasesPage
