import React from 'react'
import { Button } from 'antd' // Assuming you're using Ant Design buttons

const PaginationControls = ({
	currentPage,
	onPrevious,
	onNext,
	disablePrevious,
	disableNext,
}) => (
	<div className="flex items-center gap-2 mt-5 justify-center">
		<Button
			disabled={disablePrevious}
			className="bg-[#0A4D68] text-white w-10 h-10 rounded-lg"
			onClick={onPrevious}
		>
			{'<'}
		</Button>
		<p className="text-lg font-semibold">{Math.floor(currentPage / 20) + 1}</p>
		<Button
			disabled={disableNext}
			className="bg-[#0A4D68] text-white w-10 h-10 rounded-lg"
			onClick={onNext}
		>
			{'>'}
		</Button>
	</div>
)

export default PaginationControls
